import { Button, Row, Space, message } from 'antd';
import { ModalOutCall } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import useConsultationStore from '@store/useConsultationStore';
import { onCopyToClipboard } from '@helper/uiHelper';
import { useCallerContext } from '@context/CallerContext';

function ConsultationButtonPanel({ isMember = false }) {
  const currentMember = useConsultationStore((state) => state.currentMember);
  const { openModal } = useOverlayContext();
  const { isOnCall } = useCallerContext();

  const handleCopy = () => {
    onCopyToClipboard(`${currentMember?.memberId} / ${currentMember?.mobile}`);
    message.success('클립보드에 복사 되었습니다');
  };

  const handleOutCall = () => {
    if (isOnCall) return;
    // 이미 전화중일때 disable
    openModal(ModalOutCall, {
      customerName: currentMember?.memberName,
      customerPhone: currentMember?.mobile,
      customerId: currentMember?.memberId,
    });
  };

  // CATS-10198 작업하면서 범위에서 제외됨.
  // const handleConsultationAppointment = () => {
  //   openModal(ModalConsultationAppointment, {
  //     customerId: currentMember?.memberId,
  //     customerName: currentMember?.memberName,
  //     customerPhone: currentMember?.mobile,
  //   });
  // };

  return (
    <>
      <Button
        type="primary"
        onClick={handleOutCall}
        disabled={isOnCall || !currentMember?.mobile}
        className="ml-[20px]"
      >
        전화걸기
      </Button>
      {isMember && (
        <Row className="mt-5">
          <Space direction="vertical">
            <Space wrap>
              <Button onClick={handleCopy}>클립보드에 복사</Button>
            </Space>
          </Space>
        </Row>
      )}
    </>
  );
}

export default ConsultationButtonPanel;
