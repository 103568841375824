import { useMemo } from 'react';
import { Modal, Button, Descriptions, Tag } from 'antd';

import { useOverlayContext } from '@context/OverlayContext';
import { toPhoneNumberFormat } from '@helper/formatHelper';

import { useCallSocketStore } from '@feature/cti/store/useCallSocketStore';
import { CUSTOMER_SERVICE_INFO } from '@feature/cti/data/constants';
import PickupCallButton from '@feature/cti/components/PickupCallButton';
import CopyableText from '@components/common/CopyableText';
import ModalContainer from './base/ModalContainer';

function ModalCallNotification() {
  const { closeModal } = useOverlayContext();
  const call = useCallSocketStore((state) => state.call);

  const handleClose = () => {
    closeModal();
  };

  const handlePickUp = () => {
    closeModal();
  };

  const renderInboundLocationTag = useMemo(() => {
    const { cid } = call;
    const obj = CUSTOMER_SERVICE_INFO.find(({ telPattern }) =>
      new RegExp(telPattern).test(cid),
    );

    return obj ? (
      <Tag color={obj.color}>{obj.label}</Tag>
    ) : (
      <Tag color="green">일반전화 / {cid}</Tag>
    );
  }, [call]);

  return (
    <ModalContainer>
      <Modal
        open
        title="전화 알림"
        closable={false}
        footer={
          <div className="flex justify-between gap-2">
            <Button onClick={handleClose}>닫기</Button>
            <PickupCallButton onPickup={handlePickUp} />
          </div>
        }
        width="60%"
      >
        <Descriptions bordered className="my-5">
          <Descriptions.Item
            label="인입경로"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            <div className="w-fit">{renderInboundLocationTag}</div>
          </Descriptions.Item>
          <Descriptions.Item
            label="전화번호"
            labelStyle={{ width: '20%' }}
            span={3}
          >
            <div className="w-fit">
              <CopyableText
                text={toPhoneNumberFormat(call.customerPhoneNumber)}
              />
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </ModalContainer>
  );
}

export default ModalCallNotification;
