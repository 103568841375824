import { Tooltip } from 'antd';
import { useState } from 'react';

function CopyableText({ text, className }) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      setTooltipVisible(true);
      setTimeout(() => setTooltipVisible(false), 800);
    } catch (err) {
      console.error('클립보드 복사 실패:', err);
    }
  };

  return (
    <Tooltip open={tooltipVisible} title="복사되었습니다!" placement="top">
      <span
        role="button"
        tabIndex={0}
        onClick={handleCopy}
        onKeyDown={() => {}}
        className={`cursor-pointer py-2 rounded-md hover:bg-gray-100 hover:text-black transition-colors ${className}`}
      >
        {text}
      </span>
    </Tooltip>
  );
}

export default CopyableText;
