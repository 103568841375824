import { useEffect, useState } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';

import { isMemberNumber, isPhoneNumber } from '@helper/validateHelper';
import { useGetMembers } from '@api';
import { useOverlayContext } from '@context/OverlayContext';
import useConsultationStore from '@store/useConsultationStore';
import { MEMBER_TYPE_CD } from '@constants/consultation';

function SearchMember() {
  const navigate = useNavigate();
  const { openAlert } = useOverlayContext();

  const { key } = useParams();
  const [form] = Form.useForm();

  const [payload, setPayload] = useState({ searchKey: '', searchValue: '' });
  const isConsultationDetailPage = useMatch('/consultation/:id');

  const { setCurrentMember, setMemberData, setNonMemberOrOnlyStudent } =
    useConsultationStore((state) => state);
  const { data: originMembers } = useGetMembers(payload);

  const validateMemberRule = (_, value) => {
    if (!isMemberNumber(value) && !isPhoneNumber(value)) {
      return Promise.reject(new Error('회원ID 또는 연락처를 입력해주세요.'));
    }

    return Promise.resolve();
  };

  const handleGetMembers = ({ searchValue }) => {
    const searchKey = isPhoneNumber(searchValue) ? 'phone' : 'id';
    setPayload({
      searchKey,
      searchValue,
      query: Date.now(),
    });
  };

  const handleSubmit = (formValue) => {
    const { errors } = form.getFieldsError();

    // 데이터가 없다면
    if (!isEmpty(errors) || isEmpty(formValue)) {
      return;
    }

    const mobile = formValue?.mobile || formValue;
    navigate(`/consultation/${mobile}`);
  };

  useEffect(() => {
    if (!originMembers || !payload.searchKey) return;

    const total = Number(originMembers?.total);
    const isOnlyStudent = originMembers?.list?.every((member) =>
      member.memberTypes?.every(
        (type) => type.memberTypeCd === MEMBER_TYPE_CD.STUDENT,
      ),
    );

    // ID로 검색한 경우
    if (payload.searchKey === 'id') {
      if (total === 0) {
        openAlert(`일치하는 회원 ID가 없습니다. (${payload.searchValue})`);
        navigate('/consultation');

        return;
      }

      if (isOnlyStudent) {
        openAlert(`학생은 검색할 수 없습니다. (${payload.searchValue})`);
        navigate('/consultation');

        return;
      }

      // 정상적인 경우
      setMemberData(originMembers);
      setNonMemberOrOnlyStudent(false);

      return;
    }

    // 전화번호로 검색한 경우
    if (payload.searchKey === 'phone') {
      if (total === 0 || isOnlyStudent) {
        // 비회원이거나 학생만 있는 경우 -> 비회원 처리
        setMemberData({});
        setCurrentMember({
          mobile: payload.searchValue,
          memberName: '',
          memberId: '',
        });

        setNonMemberOrOnlyStudent(true);

        return;
      }

      // 정상적인 경우
      setMemberData(originMembers);
      setNonMemberOrOnlyStudent(false);
    }
  }, [
    originMembers,
    payload.searchKey,
    payload.searchValue,
    navigate,
    openAlert,
    setCurrentMember,
    setMemberData,
    setNonMemberOrOnlyStudent,
  ]);

  useEffect(() => {
    if (!isConsultationDetailPage) {
      setPayload({ searchKey: '', searchValue: '' });
    }
  }, [isConsultationDetailPage]);

  // 상세 페이지 진입 시 목록 조회
  useEffect(() => {
    form.setFieldsValue({ userInfo: key || '' });
    handleGetMembers({ searchValue: key });
  }, [form, key]);

  return (
    <Form form={form} className="flex justify-center mr-20">
      <Form.Item
        label="회원검색"
        className="ml-10 flex items-center mb-0"
        colon={false}
        name="userInfo"
        rules={[{ validator: validateMemberRule }]}
        normalize={(value, prevValue) => {
          if (value === prevValue) return value;

          return value.replace(/[^0-9]/g, '').trim();
        }}
      >
        <Input.Search
          onSearch={handleSubmit}
          placeholder="회원ID 또는 전화번호(-생략)"
          allowClear
          enterButton="조회"
          className="w-[300px]"
        />
      </Form.Item>
    </Form>
  );
}

export default SearchMember;
