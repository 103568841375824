import { Outlet, useMatch } from 'react-router-dom';
import { Layout } from 'antd';

import { CtiPanel } from '@feature/cti';
import { CATS_LOGO } from '@assets';

import Header from './Header';
import Breadcrumb from './Breadcrumb';
import Gnb from './Gnb';
import Lnb from './Lnb';
import Copyright from './Copyright';

function Main() {
  const isLoginPage = useMatch('/login');

  return (
    <>
      {isLoginPage && (
        <Layout className="min-h-screen min-w-screen flex flex-col justify-center items-center">
          <div className="m-10">
            <img src={CATS_LOGO} alt="캣츠 로고" />
          </div>
          <Outlet />
        </Layout>
      )}
      {!isLoginPage && (
        <Layout className="min-h-screen bg-neutral-200 relative">
          <Header className="flex flex-row w-full">
            <Gnb />
          </Header>
          <Layout>
            <Lnb />
            <Layout className="p-[20px] ml-[300px]" id="content-wrapper">
              <CtiPanel />
              <Breadcrumb />
              <Layout.Content className="bg-white mt-3 px-7 py-7 min-w-[1200px] relative">
                <Outlet />
              </Layout.Content>
              {/* <Layout.Footer>
                <Copyright />
              </Layout.Footer> */}
            </Layout>
          </Layout>
        </Layout>
      )}
    </>
  );
}

export default Main;
