import produce from 'immer';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@helper/formatHelper';
import {
  AGENT_SOCKET_STATUS,
  CALL_EVENT,
  CMD_TYPE,
  ERROR_CODE,
  QUEUE_REASON,
} from '../data/constants';

export const getErrorObj = (errorCode) => ERROR_CODE[errorCode];
export const parseSocketResponse = (state, { MESSAGE }) => {
  const { CODE, RESULT, ERR } = MESSAGE;
  switch (CODE) {
    case CMD_TYPE.RESPONSE_AGENT_LOGIN:
      return produce(state, (draft) => {
        draft.queue.status =
          RESULT === 'S'
            ? AGENT_SOCKET_STATUS.READY_TO_QUEUE_CONNECT
            : AGENT_SOCKET_STATUS.NOT_READY_TO_QUEUE_CONNECT;
        draft.error = getErrorObj(ERR);
      });
    case CMD_TYPE.RESPONSE_QUEUE_LOGIN:
    case CMD_TYPE.RESPONSE_QUEUE_READY:
      return produce(state, (draft) => {
        draft.queue.connected = RESULT === 'S';
        draft.error = getErrorObj(ERR);
      });
    case CMD_TYPE.RESPONSE_TRANSFER:
    case CMD_TYPE.RESPONSE_UNTRANSFER:
    case CMD_TYPE.RESPONSE_HANGUP:
    case CMD_TYPE.RESPONSE_PICKUP:
    case CMD_TYPE.RESPONSE_OUTCALL:
    case CMD_TYPE.RESPONSE_ANSWER:
    default:
      return produce(state, (draft) => {
        draft.error = getErrorObj(ERR);
      });
  }
};

export const parseSocketEventResponse = (state, { MESSAGE }) => {
  console.log('MESSAGE : ', MESSAGE);
  switch (MESSAGE.CODE) {
    case CMD_TYPE.EVENT_PEER_STATUS:
      return produce(state, (draft) => {
        draft.device.status = MESSAGE.STATUS;
      });
    case CMD_TYPE.EVENT_QUEUE_LOGIN:
      return produce(state, (draft) => {
        draft.queue.connected = true;
        draft.queue.status = AGENT_SOCKET_STATUS.QUEUE_CONNECTED;
      });
    case CMD_TYPE.EVENT_QUEUE_READY:
      return produce(state, (draft) => {
        if (MESSAGE.STATE === 'Y') {
          draft.queue.status = AGENT_SOCKET_STATUS.ACTIVATE;
          return;
        }
        switch (MESSAGE.REASON) {
          case QUEUE_REASON.AFTER.value:
            draft.queue.status = AGENT_SOCKET_STATUS.AFTER;
            return;
          case QUEUE_REASON.LEAVE.value:
            draft.queue.status = AGENT_SOCKET_STATUS.LEAVE;
            return;
          case QUEUE_REASON.AT_TABLE.value:
            draft.queue.status = AGENT_SOCKET_STATUS.AT_TABLE;
            return;
          case QUEUE_REASON.PAUSE.value:
            draft.queue.status = AGENT_SOCKET_STATUS.PAUSE;
            break;
          default:
            break;
        }
      });
    case CMD_TYPE.EVENT_QUEUE_LOGOUT:
      return produce(state, (draft) => {
        draft.queue.connected = false;
        draft.queue.status = AGENT_SOCKET_STATUS.NOT_READY_TO_QUEUE_CONNECT;
      });
    case CMD_TYPE.EVENT_RING:
      return produce(state, (draft) => {
        draft.latestAgentCallEvent = CALL_EVENT.RINGING;
        draft.call.customerPhoneNumber = MESSAGE.TEL;
        draft.call.cid = MESSAGE.CID; // 발신의 경우: 발신번호(상대에게 표시되는 전화번호) | 수신의 경우: 인입된 대표번호 (전화번호;
        draft.call.agentNumber = MESSAGE.PEER; // 내선번호
        draft.call.eventType = MESSAGE.TYPE; // 발신('O') | 수신('I') | 착신('T')
        draft.call.ivr = MESSAGE.IVR;
        draft.call.startTime = dayjs().format(DATE_FORMAT.FULL_DATETIME);
      });
    case CMD_TYPE.EVENT_ANSWER:
      return produce(state, (draft) => {
        draft.latestAgentCallEvent = CALL_EVENT.ANSWER;
        draft.call.customerNumber = MESSAGE.TEL;
        draft.call.cid = MESSAGE.CID;
        draft.call.agentNumber = MESSAGE.PEER;
        draft.call.eventType = MESSAGE.TYPE;
        draft.call.file = MESSAGE.KEY;
        draft.call.ivr = MESSAGE.IVR;
        draft.call.startTime = dayjs().format(DATE_FORMAT.FULL_DATETIME);
      });
    case CMD_TYPE.EVENT_HANGUP:
      return produce(state, (draft) => {
        draft.latestAgentCallEvent = CALL_EVENT.HANGUP;
        draft.call.endTime = dayjs().format(DATE_FORMAT.FULL_DATETIME);
      });
    default:
      return {};
  }
};
