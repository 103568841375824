import { CustomerServiceFilled } from '@ant-design/icons';
import { useGetTodayCall } from '@api/consultation';
import CopyableText from '@components/common/CopyableText';
import { CALL_TYPE } from '@constants/consultation';
import { toPhoneNumberFormat } from '@helper/formatHelper';
import useAccountStore from '@store/useAccountStore';
import { Table } from 'antd';
import { useState } from 'react';

const columns = [
  {
    title: '시간',
    dataIndex: 'startTime',
    key: 'startTime',
    align: 'center',
    sorter: (a, b) => a.startTime.localeCompare(b.startTime),
  },
  {
    title: '전화번호',
    dataIndex: 'customerPhone',
    key: 'customerPhone',
    align: 'center',
    render: (customerPhone) => (
      <CopyableText text={toPhoneNumberFormat(customerPhone)} />
    ),
  },
  {
    title: 'I/O',
    dataIndex: 'callType',
    key: 'callType',
    align: 'center',
    render: (_callType) => CALL_TYPE[_callType].LABEL,
  },
];

export function TodayConsultationTable() {
  const agentNumber = useAccountStore((state) => state.agentNumber);

  const [page, setPage] = useState(1);

  // const { data: todayCallData } = useGetTodayCall({
  //   agentNumber,
  //   page,
  // });

  return (
    <Table
      title={TableTitle}
      // dataSource={todayCallData?.list || []}
      dataSource={[]}
      columns={columns}
      className="p-4 mb-[100px]"
      pagination={{
        position: ['bottomCenter'],
        showSizeChanger: false,
        // total: todayCallData?.total,
        total: 0,
        current: page,
        onChange: (current) => setPage(current),
        pageSize: 5,
        className: 'w-[280px] flex-wrap-nowrap',
      }}
    />
  );
}

function TableTitle() {
  return (
    <div className="text-sm font-semibold">
      오늘의 통화목록
      <CustomerServiceFilled className="ml-2" />
    </div>
  );
}
