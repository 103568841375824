import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'ahooks';
import { Button, Col, Form, Row, Table } from 'antd';

import { FormRangeDatePicker, FormInput } from '@components/form';
import { Search } from '@components/ui';
import {
  DATE_FORMAT,
  toDateFormat,
  toPhoneNumberFormat,
} from '@helper/formatHelper';
import { CALL_TYPE } from '@constants/consultation';
import useCodeStore, { CODE_KEY } from '@store/useCodeStore';
import useConsultation from '@hooks/consultation/useConsultation';
import CopyableText from '@components/common/CopyableText';

/**
 * 상담 내역 화면
 */
function ConsultationPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { [CODE_KEY.CONSULTATION_CATEGORY]: consultCategories } = useCodeStore(
    (state) => state.code,
  );

  const goDetail = useCallback(
    (id, phone) => {
      const navigateParams = id ? `${id}` : `${phone}`;
      navigate(`/consultation/${navigateParams}`);
    },
    [navigate],
  );

  const { consultation, handleSetPage, handleSetPayload } = useConsultation();

  const handleSubmit = (formValue) => {
    const {
      consultDate: [startDate, endDate],
    } = formValue;
    const payload = {
      customerName: formValue.customerName ? formValue.customerName : undefined,
      customerPhone: formValue.customerPhone
        ? formValue.customerPhone.replace(/-/g, '')
        : undefined,
      startDate: toDateFormat(startDate, DATE_FORMAT.DATE),
      endDate: toDateFormat(endDate, DATE_FORMAT.DATE),
    };

    handleSetPayload({
      ...payload,
      page: 1,
    });
  };

  const columns = useMemo(() => {
    return [
      {
        title: '카테고리',
        dataIndex: 'category',
        render: (category) => consultCategories?.[category] || '-',
      },
      {
        title: '회원명',
        dataIndex: 'customerName',
        width: 100,
        render: (customerName, record) => (
          <Button
            className="p-0"
            type="link"
            onClick={() => goDetail(record.customerId, record.customerPhone)}
          >
            {customerName}
          </Button>
        ),
      },
      {
        title: '전화번호',
        dataIndex: 'customerPhone',
        render: (customerPhone) => (
          <CopyableText text={toPhoneNumberFormat(customerPhone)} />
        ),
      },
      { title: '내용', dataIndex: 'content', width: 700 },
      {
        title: '날짜',
        dataIndex: 'createdDate',
        render: (createdDate) => toDateFormat(createdDate),
      },
      { title: '상담사', dataIndex: 'agentName' },
      {
        title: '수신 / 발신',
        dataIndex: 'callType',
        render: (callType) => CALL_TYPE[callType]?.LABEL || '-',
      },
    ];
  }, [consultCategories, goDetail]);

  useMount(() => {
    form.submit();
  });

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Search>
        <Search.Item>
          <FormInput
            label="회원명"
            name="customerName"
            placeholder="회원명을 입력하세요."
          />
        </Search.Item>
        <Search.Item>
          <FormInput
            label="전화번호"
            name="customerPhone"
            placeholder="전화번호를 입력하세요."
          />
        </Search.Item>
        <Search.Item>
          <FormRangeDatePicker label="상담 날짜" name="consultDate" required />
        </Search.Item>
      </Search>
      <Row justify="space-between" align="bottom">
        <Col className="font-semibold">
          총&nbsp;
          <span className="text-secondary">{consultation?.total || 0}</span>개
        </Col>
      </Row>
      <Table
        className="pt-3"
        // TODO: 백엔드에서 중복 데이터 값을 복사해놔 unique key error가 뜨는데, 데이터 정제 후 재확인 필요
        rowKey={(_consultation) => _consultation.id}
        columns={columns}
        dataSource={consultation?.list}
        pagination={{
          showSizeChanger: false,
          total: consultation?.total || 0,
          current: consultation?.pageNum || 1,
          onChange: (current) => handleSetPage(current),
        }}
      />
    </Form>
  );
}

export default ConsultationPage;
