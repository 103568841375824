import { useGetMemberDetail } from '@api/account';
import { isEmpty } from 'lodash';

import useConsultationStore from '@store/useConsultationStore';
import { useEffect } from 'react';
import {
  useGetMemberConsultation,
  useGetNonMemberConsultation,
} from '@api/consultation';
import { MEMBER_TYPE_CD } from '@constants/consultation';

export default function useConsultationDetail({ page = 1 } = {}) {
  const {
    currentMember,
    memberData,
    setCurrentMember,
    nonMemberOrOnlyStudent,
  } = useConsultationStore();

  /**
   * 아래 조건에 따라 api 요청 타입과 회원구분 텍스트 결정
   * 선생님 + 학부모 -> parents, "학부모"
   * 선생님 -> teacher, "선생님"
   * 학부모 -> parents, "학부모"
   * (memberData는 이미 학생 제외된 값이라 고려할 필요 없음)
   */
  const requestDetailType =
    memberData?.memberTypes?.length === 1 &&
    memberData?.memberTypes[0]?.memberTypeCd === MEMBER_TYPE_CD.TEACHER
      ? 'teacher'
      : 'parents';

  const displayTypeName = memberData?.memberTypes
    ?.map((type) => type.memberTypeNm)
    .join(', ');

  const { data: memberDetail } = useGetMemberDetail({
    type: requestDetailType,
    memberId: memberData?.memberId,
  });

  // SearchMember 컴포넌트에서 설정한 memberData값, 그리고 그를 통해 받아온 memberDetail 값을 통해 현재 상담의 대상이 되는 회원의 정보를 설정 (비회원이 아닌 회원만)
  useEffect(() => {
    if (!isEmpty(memberDetail) && !isEmpty(memberData)) {
      setCurrentMember({
        ...memberDetail,
        displayTypeName,
      });
    }
  }, [memberData, memberDetail, displayTypeName, setCurrentMember]);

  const { data: memberConsultationData } = useGetMemberConsultation(
    {
      page,
      customerId: currentMember?.memberId,
    },
    {
      enabled: Boolean(currentMember?.memberId),
    },
  );

  const { data: nonMemberConsultationData } = useGetNonMemberConsultation(
    {
      page,
      customerPhoneNumber: currentMember?.mobile,
    },
    {
      enabled: Boolean(currentMember?.mobile),
    },
  );

  return {
    nonMemberOrOnlyStudent,
    consultation: nonMemberOrOnlyStudent
      ? nonMemberConsultationData
      : memberConsultationData,
  };
}
