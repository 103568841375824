import { useEffect, useMemo, useState } from 'react';
import { Layout, Menu, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import workConfig from '@config/private/workConfig';
import statusConfig from '@config/private/statusConfig';
import scheduleConfig from '@config/private/scheduleConfig';
import { AuditOutlined } from '@ant-design/icons';
import { TodayConsultationTable } from './components/TodayConsultationTable';

function Lnb() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState(['work']);
  const [selectKey, setSelectKey] = useState('consultation');

  const [collapsed, setCollapsed] = useState(false);

  // const lnbItems = privateRouteConfig
  //   .filter((item) => item.path.split('/').length === 1)
  //   .map((item) => item.option);

  const workItems = workConfig
    .filter((item) => item.path.split('/').length === 1)
    .map((item) => item.option);

  // const statusItem = statusConfig
  //   .filter((item) => item.path.split('/').length === 1)
  //   .map((item) => item.option);

  // const scheduleItem = scheduleConfig
  //   .filter((item) => item.path.split('/').length === 1)
  //   .map((item) => item.option);

  const lnbItems = [
    {
      label: '업무',
      key: 'work',
      children: workItems,
      icon: <AuditOutlined />,
    },
    // ...statusItem,
    // ...scheduleItem,
  ];

  const handleNavigate = ({ key }) => {
    navigate(`/${key}`);
    setSelectKey(key);
  };

  const handleOpenChange = (keys) => setOpenKeys(keys);

  const currentOpenKey = useMemo(() => {
    const key = location.pathname.substring(1);
    let openKey = null;
    if (workItems.some((item) => item.key === key)) openKey = 'work';

    return openKey;
  }, [workItems, location.pathname]);

  useEffect(() => {
    const key = location.pathname.substring(1);
    if (currentOpenKey)
      setOpenKeys((prevOpenKeys) => [...prevOpenKeys, currentOpenKey]);
    setSelectKey(key);
  }, [currentOpenKey, location.pathname]);

  useEffect(() => {
    if (!collapsed) {
      setOpenKeys(['work']);
    } else {
      setOpenKeys([]);
    }
  }, [collapsed]);

  return (
    <Layout.Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={300}
      className="overflow-auto h-screen fixed left-0 top-[58px] bottom-0 z-[1000]"
    >
      <div className="flex flex-col justify-between h-full">
        <Menu
          theme="light"
          items={lnbItems}
          defaultSelectedKeys={['work']}
          defaultOpenKeys={['consultation']}
          selectedKeys={selectKey}
          openKeys={openKeys}
          onClick={handleNavigate}
          onOpenChange={handleOpenChange}
          mode="inline"
        />
        {!collapsed && <TodayConsultationTable />}
      </div>
    </Layout.Sider>
  );
}

export default Lnb;
