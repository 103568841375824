import { Descriptions } from 'antd';

import { toPhoneNumberFormat } from '@helper/formatHelper';
import useConsultationStore from '@store/useConsultationStore';

import CopyableText from '@components/common/CopyableText';
import ConsultationButtonPanel from './ConsultationButtonPanel';
import TabConsultationHistory from './tabs/TabConsultationHistory';

/**
 *
 * 비회원 상담 내역 상세 화면
 */
function DescriptionNonMember() {
  const currentMember = useConsultationStore((state) => state.currentMember);

  return (
    <div>
      <Descriptions
        bordered
        title={
          <>
            비회원 상담
            <ConsultationButtonPanel />
          </>
        }
        column={2}
        className="mb-10"
        size="middle"
      >
        <Descriptions.Item label="전화번호" labelStyle={{ width: '30%' }}>
          <CopyableText text={toPhoneNumberFormat(currentMember?.mobile)} />
        </Descriptions.Item>
      </Descriptions>

      <TabConsultationHistory />
    </div>
  );
}

export default DescriptionNonMember;
